@charset "UTF-8";
body {
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif; }

.navbar {
  padding: 1.1rem 1.5rem 0 1.3rem; }
  .navbar .navbar-brand {
    font-weight: bold; }
  .navbar #navbars li {
    font-size: .9rem;
    padding: .4rem .3rem .4rem .4rem; }
    .navbar #navbars li a {
      color: #333;
      border-bottom: 1px solid transparent;
      padding: .2rem .1rem 0; }
      .navbar #navbars li a i {
        font-size: 1.2rem; }
  @media (max-width: 767px) {
    .navbar {
      padding: 0; }
      .navbar .navbar-brand {
        display: none; }
      .navbar .navbar-toggler {
        z-index: 999;
        border: 0;
        padding: 1rem; }
        .navbar .navbar-toggler img {
          width: 30px; }
      .navbar #navbars {
        position: absolute;
        top: 0;
        width: 100%;
        padding: 55px .6rem .6rem;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 1px 0 4px rgba(0, 0, 0, 0.2); } }

.products #navbars li#nav-products a,
.shopinfo #navbars li#nav-shopinfo a,
.about #navbars li#nav-about a,
.faq #navbars li#nav-faq a,
.contact #navbars li#nav-contact a {
  border-bottom-color: #666; }

footer.bg-faded {
  background: #444; }
  footer.bg-faded a {
    color: #fff; }
  footer.bg-faded ul {
    padding: 0;
    margin: 1rem 0; }
    footer.bg-faded ul li {
      list-style: none;
      line-height: 1.6;
      padding: 0;
      margin-bottom: 1.3rem; }
  footer.bg-faded p {
    color: #bbb;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 7rem; }

.di--icon-circle-border {
  font-size: 1.5rem;
  border: 1px solid  #222;
  border-radius: 50%;
  padding: 5px;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin: 0 5px; }

.di--icon-circle-border:hover {
  background: #222;
  color: #fff; }

.di--animation {
  transition: all 0.5s; }

.to-page-top {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  display: none; }
  .to-page-top a {
    text-decoration: none;
    display: block;
    font-size: 30px;
    text-align: center;
    padding: 8px;
    color: #555; }

.di--ro-image img, .row img {
  transition: all 0.5s;
  opacity: 1; }

.di--ro-image img:hover, .row img:hover {
  opacity: 0.6; }

/* slick */
.slick-prev, .slick-next {
  width: 40px;
  height: 40px; }

.slick-prev:before,
.slick-next:before {
  color: #444;
  font-family: "FontAwesome";
  font-size: 42px;
  width: 40px;
  height: 40px;
  display: block; }

.slick-prev:before {
  content: '\f104'; }

.slick-next:before {
  content: '\f105'; }

.slick-prev {
  left: -40px; }

.slick-next {
  right: -40px; }

.slick-dots {
  bottom: 20px; }
  .slick-dots li {
    width: 10px; }
    .slick-dots li button {
      width: 10px; }
      .slick-dots li button:before {
        font-size: 10px; }
  @media all and (max-width: 992px) {
    .slick-dots {
      bottom: 15px; } }
  @media all and (max-width: 520px) {
    .slick-dots {
      bottom: 5px; } }

.content {
  padding-top: 130px; }
  .content p {
    font-size: 0.9rem;
    line-height: 2; }

h2 span {
  font-size: .9rem;
  display: inline-block; }

body.home {
  /*ビデオ body.demo */ }
  body.home #feature {
    min-height: 100%;
    /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh;
    /* These two lines are counted as one :-)       */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    z-index: -10000; }
  body.home .cover-video {
    height: 100%;
    height: 100vh;
    position: relative;
    width: 100%; }
  body.home #video {
    left: 0;
    min-height: 100%;
    min-height: 100vh;
    min-width: 100%;
    min-width: 100vw;
    position: fixed;
    top: 0;
    z-index: 1; }
  body.home .cover-opening {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10000;
    background: #fff;
    top: 0; }
  body.home .cover-fg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    background-image: url(/_assets/img/fg.png);
    top: 0; }
  @media all and (min-width: 768px) {
    body.home .cover-slide {
      display: none; } }
  @media all and (max-width: 767px) {
    body.home {
      /*.cover-fg,*/ }
      body.home .cover-video {
        display: none; }
      body.home .cover-slide {
        min-height: 100%;
        /* Fallback for browsers do NOT support vh unit */
        min-height: 100vh;
        /* These two lines are counted as one :-)       */
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: -100; } }
  body.home .content,
  body.home footer {
    position: relative;
    z-index: 1000; }
  body.home .content {
    background: #fff;
    padding: 1px 0;
    margin-top: -90px; }
    body.home .content #news h2 {
      line-height: 1.5; }
      body.home .content #news h2 i {
        display: none; }
    body.home .content #news p {
      line-height: 1.6;
      color: #444; }
      body.home .content #news p strong {
        margin-bottom: .15rem;
        display: inline-block;
        color: #000;
        font-size: 1rem;
        font-weight: normal; }
  body.home #instagram .insta-photos {
    min-height: 200px;
    width: 100%; }
    body.home #instagram .insta-photos a {
      display: block;
      float: left; }
      body.home #instagram .insta-photos a img {
        width: 100%;
        display: block; }
      @media (min-width: 768px) {
        body.home #instagram .insta-photos a {
          width: 16.666%; } }
      @media (max-width: 767px) {
        body.home #instagram .insta-photos a {
          width: 25%; } }
  body.home #customer-testimonials {
    background: #F3F1EE; }
    body.home #customer-testimonials h2 a {
      text-decoration: none; }
    @media (max-width: 575px) {
      body.home #customer-testimonials .slick-slider button.slick-arrow {
        display: none !important; } }
  body.home footer {
    margin-top: 0 !important; }

.products .content {
  padding-top: 0; }

.products .lineup .item a {
  border: 1px solid #eee;
  display: block;
  height: 120px;
  text-decoration: none; }
  .products .lineup .item a img {
    height: 100%;
    float: left;
    margin-right: .6rem; }
  .products .lineup .item a p {
    font-size: .9rem;
    padding-top: 2rem; }
    .products .lineup .item a p span {
      font-size: .8rem;
      display: block; }

.products #care img {
  float: left;
  margin-right: 1rem; }

.products #lineup .item a {
  border: 1px solid #eee;
  display: block;
  height: 120px;
  text-decoration: none; }
  .products #lineup .item a img {
    height: 100%;
    float: left;
    margin-right: .6rem; }
  .products #lineup .item a p {
    font-size: .9rem;
    padding-top: 2rem; }
    .products #lineup .item a p span {
      font-size: .8rem;
      display: block; }

@media all and (min-width: 992px) {
  .products #lineup .item {
    flex: 0 0 20%; }
    .products #lineup .item a {
      height: auto;
      text-align: center; }
      .products #lineup .item a img {
        float: none;
        margin-right: 0; }
      .products #lineup .item a p {
        padding-top: 0; }
  .products #lineup .item-plon a {
    height: 100% !important;
    background: url("/products/uploads/plon-case01-photo1.jpg") no-repeat center center/cover;
    display: block;
    text-align: center;
    width: 100%; }
    .products #lineup .item-plon a img {
      width: 80%;
      padding: 10% 0; } }

@media all and (max-width: 991px) {
  .products #lineup .item-plon a {
    background: #E3E8EE; }
    .products #lineup .item-plon a p {
      display: flex;
      align-items: center;
      padding-top: 0 !important;
      height: 100%; }
      .products #lineup .item-plon a p img {
        float: none;
        width: 120px; } }

.products-top .headline {
  background: url("/uploads/products-1.jpg") no-repeat/contain;
  height: 52.16vw;
  position: relative;
  width: 100%; }
  .products-top .headline h1 img {
    position: absolute;
    bottom: 20px;
    margin: 0 auto;
    left: 0;
    right: 0; }

.products-top .materials {
  background: url("/uploads/products-2.jpg") no-repeat top left/contain; }
  .products-top .materials p a {
    text-decoration: underline; }

.products-top .color {
  background: url("/uploads/products-3.jpg") no-repeat left 30px/contain; }
  .products-top .color h2 {
    height: 38vw; }
  .products-top .color dl {
    line-height: 1.6;
    font-size: .9rem; }
  @media (min-width: 768px) {
    .products-top .color .col-md-4 {
      margin-bottom: 1rem;
      padding-bottom: 1rem; }
      .products-top .color .col-md-4 div {
        height: 5px;
        background: #eee;
        display: block;
        margin-bottom: 10px; }
      .products-top .color .col-md-4:nth-child(3n) dl {
        border-right-color: #fff; } }
  @media (max-width: 767px) {
    .products-top .color .col-md-4 {
      display: flex;
      justify-content: space-between; } }
  @media (max-width: 767px) and (min-width: 576px) {
    .products-top .color .col-md-4 {
      padding: 0; } }
  @media (max-width: 767px) {
      .products-top .color .col-md-4 dl {
        flex: 1;
        margin-bottom: 0; }
        .products-top .color .col-md-4 dl dd {
          margin-bottom: 0; }
      .products-top .color .col-md-4 + .col-md-4 {
        border-top: 1px solid #ddd;
        margin-top: .7rem;
        padding-top: .8rem; }
      .products-top .color .col-md-4 div {
        width: 20px !important;
        background: #eee;
        display: block;
        border-right: 10px solid #fff; } }
  @media (max-width: 575px) {
    .products-top .color .container > .row {
      margin-left: 0;
      margin-right: 0; }
      .products-top .color .container > .row .col-md-4 {
        padding-left: 0;
        padding-right: 0; } }

.products-top .technology {
  background: url("/uploads/products-4.jpg") no-repeat left 20px/contain; }
  .products-top .technology h2 {
    height: 34.7vw;
    padding-top: 5vw; }
    @media (max-width: 480px) {
      .products-top .technology h2 {
        padding-top: 38vw;
        height: auto; } }

.products-detail h2 {
  vertical-align: middle; }
  .products-detail h2 span {
    font-size: 75%;
    vertical-align: middle;
    padding-left: .3rem; }

.products-detail .bgd {
  background: url("/uploads/border-gray-double.png") repeat-x center center; }
  .products-detail .bgd .bgd-wrap {
    display: inline;
    background: #fff;
    padding: .2rem 1rem;
    line-height: 1.3; }
  @media (max-width: 480px) {
    .products-detail .bgd {
      background: none; } }

.products-detail .item-body h1 {
  font-weight: bold; }
  .products-detail .item-body h1 span {
    font-size: 1.3rem;
    font-weight: normal;
    display: inline-block; }

.products-detail .item-body p {
  font-size: 1rem;
  line-height: 1.8; }

.products-detail .item-body p.small {
  font-size: .8rem;
  color: #666; }
  .products-detail .item-body p.small a {
    color: #666;
    text-decoration: underline; }

.products-detail .item-body .btn {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .products-detail .item-body .btn:hover {
    background-color: #fff;
    border-color: #171a1d;
    color: #171a1d; }

.products-detail .item-body .btn.disabled {
  width: 10rem;
  background: #d5d5d5;
  color: #fff;
  opacity: 1; }

.products-detail #variations .color {
  font-size: .8rem; }
  .products-detail #variations .color img {
    margin: .3rem 0; }
  .products-detail #variations .color select[disabled] {
    color: #292b2c; }

.products-detail #care {
  background: #F3F1EE; }
  .products-detail #care ul {
    padding-left: 1.1rem; }

.products-detail .mfp-bg {
  background: #fff; }

.products-detail .mfp-bottom-bar {
  margin-top: -30px; }
  .products-detail .mfp-bottom-bar .mfp-title {
    color: #444;
    text-align: center;
    font-size: 14px; }
  .products-detail .mfp-bottom-bar .mfp-counter {
    display: none; }

.products-detail .mfp-arrow {
  margin-top: -90px; }

.products-detail .mfp-arrow:before {
  border: 0;
  font-family: FontAwesome;
  font-size: 100px;
  color: #444;
  opacity: 1;
  margin-top: 0; }

.products-detail .mfp-arrow-left:before {
  content: "\f104"; }

.products-detail .mfp-arrow-right:before {
  content: "\f105"; }

.products-detail .mfp-arrow:after {
  border: 0; }

@media (min-width: 768px) {
  .products-detail .slider {
    position: absolute;
    width: 66.666%; }
    .products-detail .slider img {
      width: 100% !important; }
    .products-detail .slider .item-info {
      font-size: 0.8rem;
      display: inline-block; }
  .products-detail .item-body {
    min-height: 66.66vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .products-detail .item-body h1 {
      margin-top: 5rem; }
  .products-detail .item-detail {
    min-height: 66.66vw; }
    .products-detail .item-detail .item-body {
      width: 45vw;
      display: block;
      position: absolute;
      left: 50vw; } }

@media (max-width: 767px) and (min-width: 576px) {
  .products-detail .item-detail .item-body {
    width: 540px;
    max-width: 100%;
    margin: 0 auto; } }

@media (max-width: 767px) and (max-width: 575px) {
  .products-detail .item-detail .item-body {
    margin: 0 15px; } }

.onlineshop.products-detail .lineup img {
  display: none; }

.onlineshop.products-detail #variations select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0; }

.about #gmap-wrap {
  width: 100%;
  padding-top: 40%;
  margin: auto; }
  .about #gmap-wrap #gmap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; }

.shopinfo #localnav a {
  margin: 0 .5rem .5rem 0;
  display: inline-block; }

.shopinfo dd a {
  word-break: break-all; }

.voice-top .block h2 {
  font-weight: bold;
  line-height: 1.34; }

.voice-top .block p.user-name {
  font-size: .75rem; }

@media (min-width: 768px) {
  .voice-top .block:nth-child(2n) {
    background: #F3F1EE; }
    .voice-top .block:nth-child(2n) .col-12:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
  .voice-top .block:nth-child(2n-1) .col-12:first-child {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start; } }

@media (max-width: 767px) {
  .voice-top .col-12:first-child {
    margin-bottom: 1rem; }
  .voice-top img {
    display: block;
    margin: 0 auto; } }

.faq #bg {
  position: fixed !important;
  height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  height: 100vh;
  /* These two lines are counted as one :-)       */
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: -100; }
  .faq #bg .backstretch {
    min-height: 100vh; }

.faq .content {
  z-index: 100; }
  .faq .content li {
    font-size: .9rem;
    margin-bottom: .2rem; }
  .faq .content .faq-q {
    margin-bottom: 1rem;
    line-height: 1.3; }

.order-total {
  position: fixed;
  top: 0;
  right: 0;
  background: #222;
  color: #fff;
  padding: 30px; }

.contact .content {
  padding-bottom: 300px; }
  .contact .content dd {
    margin-bottom: 1.6rem; }
    .contact .content dd input, .contact .content dd textarea {
      width: 100%; }

.onlineshop #site-name {
  padding: 1.1rem 0 2rem 1.3rem; }
  .onlineshop #site-name a {
    font-weight: 700;
    display: inline-block;
    padding: .25rem 0;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap; }

.onlineshop #cart_content {
  top: 20px;
  right: 20px;
  position: fixed;
  z-index: 3000;
  background: #fff; }

.onlineshop input[type="button"] {
  cursor: pointer; }

.onlineshop .add-cart input.submit {
  border: 1px solid #999;
  padding: .3rem;
  background: #999;
  color: #fff;
  width: 100%;
  border-radius: 3px;
  font-size: .75rem; }
  .onlineshop .add-cart input.submit:active, .onlineshop .add-cart input.submit:hover {
    background: #fff;
    color: #444; }
  .onlineshop .add-cart input.submit.disabled {
    background: #fff;
    color: #444; }

.onlineshop .add-cart p {
  color: #c33;
  font-size: .8rem;
  line-height: 1.3;
  margin-top: .3rem; }

.onlineshop #content-nav #shop-top a {
  border: 1px solid #888;
  padding: .5rem 1rem;
  color: #888;
  width: 100%;
  border-radius: 3px; }
  .onlineshop #content-nav #shop-top a:hover {
    text-decoration: none;
    background: #888;
    color: #fff; }

.onlineshop #content-nav #legal p {
  font-size: .85rem; }
  .onlineshop #content-nav #legal p a {
    color: #666; }

.onlineshop .content .require {
  color: #cc4646; }

.onlineshop-top .lineup .item a {
  text-align: center;
  padding: 10px 0;
  height: auto; }
  .onlineshop-top .lineup .item a img {
    float: none; }
  .onlineshop-top .lineup .item a p {
    padding-top: 1rem; }

.onlineshop-top #shop-top {
  display: none; }

.products-plon .localnav h3 {
  font-size: 12px; }

.products-plon .localnav p {
  font-size: 10px; }

.products-plon .item-detail {
  background: url("/uploads/products-plon-feature1.jpg") no-repeat 0 0/contain; }
  .products-plon .item-detail .btn.disabled {
    background-color: #fff;
    color: #1d2124;
    border-color: #171a1d; }
    .products-plon .item-detail .btn.disabled:hover {
      background-color: #171a1d;
      color: #fff; }
  .products-plon .item-detail .item-body1 {
    position: absolute;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%; }
    .products-plon .item-detail .item-body1 h1 {
      margin-top: 0; }
      .products-plon .item-detail .item-body1 h1 img {
        width: 155px; }
    .products-plon .item-detail .item-body1 h2 {
      color: #fff;
      width: 300px;
      text-align: right; }
  .products-plon .item-detail .item-body3 {
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    width: 100vw; }
    .products-plon .item-detail .item-body3 p {
      padding-right: 20px; }

@media (max-width: 767px) {
  .products-plon .item-detail {
    padding-top: 50.3vw; }
    .products-plon .item-detail .item-body {
      margin: 0 auto !important; }
    .products-plon .item-detail .item-body1 {
      top: 15vw; }
    .products-plon .item-detail .item-body2 {
      text-align: center;
      padding-top: 1.5rem; }
      .products-plon .item-detail .item-body2 p {
        margin-bottom: 0 !important; }
      .products-plon .item-detail .item-body2 .btn {
        background: transparent;
        color: #171a1d; }
    .products-plon .item-detail .item-body3 p {
      font-size: .8rem; }
  .products-plon #case h2 {
    margin-top: 0 !important; }
  .products-plon #case .case p {
    margin-top: .5rem;
    line-height: 1.6; } }

@media (min-width: 768px) {
  .products-plon .item-detail, .products-plon .item-body {
    min-height: 50.3vw !important;
    display: flex !important; }
  .products-plon .item-body {
    left: 0 !important; }
  .products-plon .item-detail .item-body1 {
    width: 100vw !important; }
  .products-plon .item-detail .item-body2 {
    left: 10vw !important;
    width: 40vw !important;
    justify-content: flex-end; }
    .products-plon .item-detail .item-body2 .btn {
      width: 11rem; }
    .products-plon .item-detail .item-body2 .btn.disabled {
      background: transparent;
      color: #171a1d; }
  .products-plon #case h2 {
    margin-top: 0 !important; }
    .products-plon #case h2 + p {
      font-size: 1rem; }
  .products-plon #case .case {
    position: relative; }
    .products-plon #case .case p {
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      opacity: 0;
      transition: all 0.5s; }
      .products-plon #case .case p:hover {
        opacity: 1; } }

.products-plon #colors p {
  font-size: .8rem; }

.products-plon #size img {
  max-width: 300px;
  margin-bottom: 1rem; }

.products-plon #size p {
  text-align: left; }

.products-plon #nui {
  border: 1px solid #eee; }
  .products-plon #nui dl {
    margin: 0;
    font-size: 13px; }

.products-plon #buy .btn {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.products-plon #buy .btn:hover {
  background-color: #fff;
  border-color: #171a1d;
  color: #171a1d; }

.bp-account #aform-999 .aform-content dl input, .bp-account #aform-999 .aform-content dl textarea {
  font-size: .95rem; }

.bp-account #aform-999 .aform-content dl .aform-twice-note {
  color: #595959;
  font-size: .9rem;
  margin-bottom: .3rem; }
