body{
  font-family:"Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
}

.navbar{
  padding:1.1rem 1.5rem 0 1.3rem;
  .navbar-brand{
    font-weight: bold;
  }
  #navbars{
    li{
      font-size: .9rem;
      padding: .4rem .3rem .4rem .4rem;
      a{
        color: #333;
        border-bottom: 1px solid transparent;
        padding: .2rem .1rem 0;
        i{
          font-size: 1.2rem;
        }
      }
    }
  }
  @media (max-width:767px) {
    padding: 0;
    .navbar-brand{
      display: none;
    }
    .navbar-toggler{
      z-index: 999;
      border: 0;
      padding: 1rem;
      img{
        width: 30px;
      }
    }
    #navbars{
      position: absolute;
      top:0;
      width: 100%;
      padding: 55px .6rem .6rem;
      background: rgba(255, 255, 255, .95);
      box-shadow: 1px 0 4px rgba(0,0,0,.2);
    }
  }
}
.products #navbars li#nav-products a,
.shopinfo #navbars li#nav-shopinfo a,
.about #navbars li#nav-about a,
.faq #navbars li#nav-faq a,
.contact #navbars li#nav-contact a{
  border-bottom-color:#666;
}

footer.bg-faded{
  background: #444;
  a{
    color: #fff;
  }
  ul{
    padding: 0;
    margin: 1rem 0 ;
    li{
      list-style: none;
      line-height: 1.6;
      padding: 0;
      margin-bottom: 1.3rem;
    }
  }
  p{
    color: #bbb;
    color: rgba(255,255,255,0.6);
    margin-top: 7rem;
  }
}


// Diルール
.di--icon-circle-border {
  font-size: 1.5rem;
  border:1px solid  #222;
  border-radius: 50%;
  padding:5px;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin: 0 5px;
}
.di--icon-circle-border:hover {
  background: #222;
  color: #fff;
}

.di--animation {
  transition: all 0.5s;
}

.to-page-top {
  position: fixed;
  right:0;
  bottom: 0;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);

  a {
    text-decoration: none;
    display: block;
    font-size: 30px;
    text-align: center;
    padding: 8px;
    color: #555;
  }

  display: none;

}


// 画像にホバーしたらアニメーションで薄くなる
.di--ro-image img, .row img {transition: all 0.5s;opacity: 1}
.di--ro-image img:hover,  .row img:hover {opacity: 0.6}


/* slick */
.slick-prev, .slick-next{width:40px;height: 40px;}
.slick-prev:before,
.slick-next:before {
  color: #444;
  font-family: "FontAwesome";
  font-size: 42px;
  width:40px;
  height: 40px;
  display: block;

}
.slick-prev:before{content: '\f104'}
.slick-next:before{content: '\f105'}
.slick-prev{left: -40px;}
.slick-next{right: -40px;}

.slick-dots{
  bottom:20px;
  li{
    width:10px;
    button{
      width:10px;
      &:before{
        font-size: 10px;
      }
    }
  }
  @media all and (max-width:992px) {
    bottom:15px;
  }
  @media all and (max-width:520px) {
    bottom:5px;
  }
}

// common
.content{
  padding-top: 130px;
  p{
    font-size: 0.9rem;
    line-height: 2;
  }
}

h2{
  span{
    font-size: .9rem;
    display: inline-block;
  }
}

//home
body.home{
  #feature {
    min-height: 100%;
    /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh;
    /* These two lines are counted as one :-)       */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    z-index: -10000;
  }


  /*ビデオ body.demo */
  .cover-video {
    height: 100%;
    height: 100vh;
    position: relative;
    width: 100%;
  }

  #video {
    left: 0;
    min-height: 100%;
    min-height: 100vh;
    min-width: 100%;
    min-width: 100vw;
    position: fixed;
    top: 0;
    z-index: 1;
  }
  .cover-opening {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10000;
    background: #fff;
    top:0;
  }
  .cover-fg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    background-image: url(/_assets/img/fg.png);
    top:0;
  }
  @media all and (min-width:768px) {
    .cover-slide{
      display: none;
    }
  }
  @media all and (max-width:767px) {
    /*.cover-fg,*/
    .cover-video{
      display: none;
    }
    .cover-slide{
      min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
      min-height: 100vh; /* These two lines are counted as one :-)       */
      display: flex;
      justify-content:center;
      align-items:center;
      position: relative;
      z-index: -100;
    }
  }

  .content,
  footer {
    position: relative;
    z-index: 1000;
  }
  .content{
    background: #fff;
    padding: 1px 0;
      margin-top:-90px;
    @media all and (min-width:768px) {
      // margin-top: 100vh;
    }
    #news{
      h2{
        line-height: 1.5;
        i{
          display: none;
        }
      }
      p{
        line-height: 1.6;
        color: #444;
        strong{
          margin-bottom: .15rem;
          display: inline-block;
          color: #000;
          font-size: 1rem;
          font-weight: normal;
        }
      }
      // ul{
      //   padding-left: 1.3rem;
      //   li{
      //     span{
      //       display: block;
      //       font-size: .9rem;
      //       margin: .2rem 0 .7rem;
      //       color: #666;
      //     }
      //   }
      // }
    }
  }
  #instagram .insta-photos{
    min-height: 200px;
    width: 100%;
    a{
      display: block;
      float: left;
      img{
        width: 100%;
        display: block;
      }
      @media (min-width:768px) {
        width: 16.666%;
      }
      @media (max-width:767px) {
        width: 25%;
      }
    }
  }
  #customer-testimonials{
    background: #F3F1EE;
    h2{
      a{
        text-decoration: none;
      }
    }
    @media (max-width:575px) {
      .slick-slider{
        button.slick-arrow{
          display: none !important;
        }
      }
    }
  }
  footer{
    margin-top: 0 !important;
  }
}



//products
.products{
  .content{
    padding-top: 0;
  }
  .lineup{
    .item{
      a{
        border: 1px solid #eee;
        display: block;
        height: 120px;
        text-decoration: none;
        img{
          height: 100%;
          float: left;
          margin-right: .6rem;
        }
        p{
          font-size: .9rem;
          padding-top: 2rem;
          span{
            font-size: .8rem;
            display: block;
          }
        }
      }
    }
  }
  #care{
    img{
      float: left;
      margin-right: 1rem;
    }
  }

  #lineup{
    .item{
      a{
        border: 1px solid #eee;
        display: block;
        height: 120px;
        text-decoration: none;
        img{
          height: 100%;
          float: left;
          margin-right: .6rem;
        }
        p{
          font-size: .9rem;
          padding-top: 2rem;
          span{
            font-size: .8rem;
            display: block;
          }
        }
      }
    }
    @media all and (min-width: 992px) {
      .item{
        flex: 0 0 20%;
        a{
          height: auto;
          text-align:center;
          img{
            float: none;
            margin-right: 0;
          }
          p{
            padding-top:0;
          }
        }
      }
      .item-plon a{
        height: 100% !important;
        background: url('/products/uploads/plon-case01-photo1.jpg') no-repeat center center / cover;
        display: block;
        text-align: center;
        width: 100%;
        img{
          width: 80%;
          padding:10% 0;
        }
      }
    }
    @media all and (max-width: 991px){
      .item-plon a{
        background: #E3E8EE;
        p{
          display: flex;
          align-items: center;
          padding-top: 0 !important;
          height: 100%;
          img{
            float: none;
            width: 120px;
          }
        }
      }
    }
    @media (max-width: 991px) and (min-width: 768px) {
      .item-plon p img{
      }
    }
  }
}
// top
.products-top{
  .headline{
    background: url("/uploads/products-1.jpg") no-repeat / contain;
    height: 52.16vw;
    position: relative;
    width: 100%;
    h1{
      img{
        position: absolute;
        bottom: 20px;
        margin: 0 auto;
        left: 0;
        right:0;
      }
    }
  }
  .lead{
  }
  .materials{
    background: url("/uploads/products-2.jpg") no-repeat top left/ contain;
    p{
      a{
        text-decoration: underline;
      }
    }
  }
  .color{
    background: url("/uploads/products-3.jpg") no-repeat left 30px/ contain;
    h2{
      height: 38vw;
    }
    dl{
      line-height: 1.6;
      font-size: .9rem;
    }
    @media (min-width:768px) {
      .col-md-4{
        // border-bottom: 1px solid #eee;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        div{
          height: 5px;
          background: #eee;
          display: block;
          margin-bottom: 10px;
        }
        dl{
          // border-right: 1px solid #eee;
        }
        &:nth-child(3n) dl{
          border-right-color: #fff;
        }
      }
    }
    @media (max-width:767px) {
      .col-md-4{
        display: flex;
        justify-content: space-between;
        @media (min-width:576px) {
          padding: 0;
        }

        dl{
          flex: 1;
          margin-bottom: 0;
          dd{
            margin-bottom: 0;
          }
        }
        & + .col-md-4{
            border-top:1px solid #ddd;
            margin-top: .7rem;
            padding-top: .8rem
        }
        div{
          width: 20px !important;
          background: #eee;
          display: block;
          border-right:10px solid #fff;
        }
      }
    }
    @media (max-width:575px) {
      .container > .row{
        margin-left: 0;
        margin-right: 0;
        .col-md-4{
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .technology{
    background: url("/uploads/products-4.jpg") no-repeat left 20px / contain;
    h2{
      height: 34.7vw;
      padding-top: 5vw;
      @media (max-width:480px) {
        padding-top: 38vw;
        height: auto;
      }
    }
  }
}

.products-detail{
  h2{
    vertical-align: middle;
    span{
      font-size: 75%;
      vertical-align: middle;
      padding-left: .3rem;
    }
  }
  .bgd{
    background: url("/uploads/border-gray-double.png") repeat-x center center;
    .bgd-wrap{
      display: inline;
      background: #fff;
      padding: .2rem 1rem;
      line-height: 1.3;
    }
    @media (max-width:480px) {
      background: none;
    }
  }
  .item-body{
    h1{
      font-weight: bold;
      span{
        font-size: 1.3rem;
        font-weight: normal;
        display: inline-block;
      }
    }
    p{
      font-size: 1rem;
      line-height: 1.8
    }
    p.small{
      font-size: .8rem;
      color: #666;
      a{
        color: #666;
        text-decoration: underline;
      }
    }
    .btn{
      color: #fff;
      background-color: #343a40;
      border-color: #343a40;
      // background-color: #e6e6e6;
      &:hover {
        background-color: #fff;
        border-color: #171a1d;
        color: #171a1d;
      }
    }
    .btn.disabled{
      width: 10rem;
      background: #d5d5d5;
      color: #fff;
      opacity: 1;
    }
  }
  #variations{
    .color{
      font-size: .8rem;
      img{
        margin: .3rem 0;
      }
      select[disabled]{
        color: #292b2c;
      }
    }
  }
  #care{
    background: #F3F1EE;
    ul{
      padding-left: 1.1rem;
    }
  }
  .mfp-bg{
    background: #fff;
  }
  .mfp-bottom-bar{
    margin-top: -30px;
    .mfp-title{
      color: #444;
      text-align: center;
      font-size: 14px;
    }
    .mfp-counter{
      display: none;
    }
  }
  .mfp-arrow{
    margin-top: -90px;
  }
  .mfp-arrow:before{
    border:0;
    font-family: FontAwesome;
    font-size: 100px;
    color: #444;
    opacity: 1;
    margin-top: 0;
  }
  .mfp-arrow-left:before{
    content: "\f104";
  }
  .mfp-arrow-right:before{
    content: "\f105";
  }
  .mfp-arrow:after{
    border: 0;
  }
  @media (min-width:768px) {
    .slider{
      position: absolute;
      width: 66.666%;
      img{width: 100% !important;}
      .item-info {
        font-size: 0.8rem;
        display: inline-block;
      }
    }
    .item-body{
      min-height: 66.66vw;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content:center;
      h1{
        margin-top: 5rem;
      }
    }
    .item-detail{
      min-height: 66.66vw;
      .item-body{
        width: 45vw;
        display: block;
        position: absolute;
        left: 50vw;
      }
    }
  }
  @media (max-width:767px) {
    .item-detail{
      .item-body{
        @media (min-width:576px) {
          width: 540px;
          max-width: 100%;
          margin: 0 auto;
        }
        @media (max-width:575px) {
          margin: 0 15px;
        }
      }
    }
  }
}
.onlineshop.products-detail{
  .lineup{
    img{
      display: none;
    }
  }
  #variations {
    select {
      -webkit-appearance: none;-moz-appearance: none;appearance: none;border: 0;
    }
  }

}

// about
.about{
  #gmap-wrap {
    width: 100%;
    padding-top: 40%;
    margin: auto;
    #gmap {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0
    }
  }
}

// shopinfo
.shopinfo{
  #localnav {
    a {
      margin:0 .5rem .5rem 0;
      display: inline-block;
    }
  }
  dd{
    a{
      word-break: break-all;
    }
  }
}

//voice
.voice-top{
  .block{
    h2{
      font-weight: bold;
      line-height: 1.34
    }
    p.user-name{
      font-size: .75rem
    }
  }
  @media (min-width:768px) {
    .block:nth-child(2n){
      background: #F3F1EE;
      .col-12:first-child{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
    .block:nth-child(2n-1){
      .col-12:first-child{
        order:2;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
      }
    }
  }
  @media (max-width:767px) {
    .col-12:first-child{
      margin-bottom: 1rem;
    }
    img{
      display: block;
      margin: 0 auto;
    }
  }
}

//faq
.faq{
  #bg{
    position: fixed !important;
    height: 100%;  /* Fallback for browsers do NOT support vh unit */
    height: 100vh; /* These two lines are counted as one :-)       */
    display: flex;
    width: 100%;
    justify-content:center;
    align-items:center;
    position: relative;
    z-index: -100;
    .backstretch{
      min-height: 100vh;
    }
  }
  .content{
    z-index: 100;
    li{
      font-size: .9rem;
      margin-bottom: .2rem;
    }
    .faq-q {
      margin-bottom: 1rem;
      line-height: 1.3;
    }
  }
}

//order

.order-total {
  position: fixed;
  top:0;
  right:0;
  background: #222;
  color: #fff;
  padding: 30px;
}


.contact{
  .content{
    padding-bottom: 300px;
    dd{
      margin-bottom: 1.6rem;
      input, textarea{
        width: 100%;
      }
    }
  }
}

//onlineshop
.onlineshop{
  #site-name{
    padding: 1.1rem 0 2rem 1.3rem;
    a{
      font-weight: 700;
      display: inline-block;
      padding: .25rem 0;
      font-size: 1.25rem;
      line-height: inherit;
      white-space: nowrap;
    }
  }
  #cart_content{
    top:20px;
    right: 20px;
    position: fixed;
    z-index: 3000;
    background: #fff;
  }
  input[type="button"]{
    cursor: pointer;
  }
  .add-cart{
    input.submit{
      border: 1px solid #999;
      padding: .3rem;
      background: #999;
      color: #fff;
      width: 100%;
      border-radius: 3px;
      font-size: .75rem;
      &:active, &:hover{
        background: #fff;
        color: #444;
      }
      &.disabled{
        background: #fff;
        color: #444;
      }
    }
    p{
      color:#c33;
      font-size: .8rem;
      line-height: 1.3;
      margin-top: .3rem;
    }
  }
  #content-nav{
    #shop-top{
      a{
        border: 1px solid #888;
        padding: .5rem 1rem;
        color: #888;
        width: 100%;
        border-radius: 3px;
        &:hover{
          text-decoration: none;
          background: #888;
          color: #fff;
        }
      }
    }
    #legal{
      p{
        font-size: .85rem;
        a{
          color: #666;
        }
      }
    }
  }
  .content{
    .require{
      color: #cc4646;
    }
  }
}
.onlineshop-top{
  .lineup .item{
    a{
      text-align: center;
      padding:10px 0;
      height:auto;
      img{
        float: none;
      }
      p{
        padding-top: 1rem;
      }
    }
  }
  #shop-top{
    display: none;
  }
}


.products-plon{
  .localnav{
    h3{font-size:12px;}
    p{font-size:10px;}
  }
  .item-detail {
    background:url('/uploads/products-plon-feature1.jpg') no-repeat 0 0 / contain;
    .btn.disabled {
      background-color: #fff;
      color:#1d2124;
      border-color: #171a1d;
      &:hover {
        background-color: #171a1d;
        color:#fff;
      }
    }
    .item-body1{
      position:absolute;
      display:flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      h1{
        margin-top:0;
        img{
          width:155px;
        }
      }
      h2{
        color: #fff;
        width: 300px;
        text-align: right;
      }
    }
    .item-body3{
      display:flex !important;
      align-items:flex-end;
      justify-content:flex-end;
      position:absolute;
      width: 100vw;
      p{
        padding-right:20px;
      }
    }
  }
  @media (max-width: 767px) {
    .item-detail{
      padding-top:50.3vw;
      .item-body{
        margin:0 auto !important;
      }
      .item-body1{
        top: 15vw;
      }
      .item-body2{
        text-align: center;
        padding-top: 1.5rem;
        p{
          margin-bottom: 0 !important;
        }
        .btn{
          background: transparent;
          color:#171a1d;
        }
      }
      .item-body3{
        p{
          font-size: .8rem;
        }
      }
    }
    #case{
      h2{
        margin-top: 0 !important;
      }
      .case{
        p{
          margin-top: .5rem;
          line-height: 1.6;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .item-detail, .item-body{
      min-height:50.3vw !important;
      display:flex !important;
    }
    .item-body{
      left: 0 !important;
    }
    .item-detail .item-body1{
      width: 100vw !important;
    }
    .item-detail .item-body2{
      left:10vw !important;
      width:40vw !important;
      justify-content:flex-end;
      .btn{
        width:11rem;
      }
      .btn.disabled{
        background: transparent;
        color:#171a1d;
      }
    }
    #case{
      h2{
        margin-top: 0 !important;
        &+p{
          font-size: 1rem;
        }
      }
      .case{
        position: relative;
        p{
          background: rgba(0,0,0,.6);
          color: #fff;
          position: absolute;
          top:0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          opacity: 0;
          transition: all 0.5s;
          &:hover{
            opacity: 1;
          }
        }
      }
    }
  }
  #colors{
    p{
      font-size: .8rem;
    }
  }
  #size{
    img{
      max-width: 300px;
      margin-bottom: 1rem;
    }
    p{
      text-align: left;
    }
  }
  #nui{
    border: 1px solid #eee;
    dl{
      margin:0;font-size:13px;
    }
  }
  #buy{
    .btn{color: #fff;
      background-color: #343a40;
      border-color: #343a40;
    }
    .btn:hover {
      background-color: #fff;
      border-color: #171a1d;
      color: #171a1d;
    }
  }
}

.bp-account{
  #aform-999{
    .aform-content dl{
      input, textarea{
        font-size: .95rem;
      }
      .aform-twice-note{
        color: #595959;
        font-size: .9rem;
        margin-bottom: .3rem;
      }
    }
  }
}
